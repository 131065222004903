import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'blog-about',
  template: `WIP`,
})
export class AboutComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
