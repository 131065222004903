<img
  class="hidden-image"
  inert
  fetchpriority="high"
  src="assets/angular.svg"
  alt="The Angular logo."
/>
<img
  class="hidden-image"
  inert
  fetchpriority="low"
  src="assets/alex-lq-small.webp"
  alt="The Angular logo."
/>
<img
  class="hidden-image"
  inert
  fetchpriority="low"
  src="assets/alex-hq-small.webp"
  alt="The Angular logo."
/>

<div *ngIf="isBrowser" class="author-name" aria-label="Aleksander Bodurri">
  <img id="animated-logo" src="assets/angular.svg" alt="The Angular logo." />
  <div class="partial-name">
    <h1
      aria-label="The name Aleksander Bodurri but with the A missing. The Angular Logo in the previous line acts as the A"
    >
      leksander Bodurri
    </h1>
  </div>
</div>
<div class="author-name-block"></div>
<section>
  <aside>
    <!-- <figure>
      <picture>
        <source srcset="assets/alex-lq-small.webp" type="image/webp" />
        <source srcset="assets/alex-hq-small.webp" type="image/webp" />
        <source srcset="assets/alex.png" type="image/png" />
        <img
          #authorImage
          width="208"
          height="224"
          fetchpriority="high"
          class="author no-quality"
          src="assets/alex-nq-small.webp"
          alt="A picture of Alex Bodurri"
        />
      </picture>
      <hr />
      <figcaption>Angular Team Member</figcaption>
      <hr />
      <figcaption>Developer - Verto Health</figcaption>
      <hr />
    </figure> -->
    <ul>
      <li>Team Lead &#64; Verto Health</li>
      <hr />
      <li>Angular Team (Dev Tools)</li>
      <hr />
      <li>Software Engineering Lecturer &#64; The University of Toronto</li>
    </ul>
  </aside>
  <article>
    <ul class="posts">
      <li>
        <blog-post-card [post]="posts[0]"></blog-post-card>
      </li>
    </ul>
    <ul class="blog-links">
      <li><a routerLink="posts">Posts</a></li>
      <!-- <li><a routerLink="about">About</a></li> -->
      <li><a routerLink="shield-maker">Shield Maker</a></li>
    </ul>
  </article>
</section>
